import React from 'react';
import styled from 'styled-components';
import search from '../assets/search.png';
import send from '../assets/send.png';
import money from '../assets/money.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #e0f7fa;
  border-radius: 12px;
  max-width: 2500px;
  margin: 0 auto;
`;

const Header = styled.h2`
  font-size: 2rem;
  color: #00796b;
  margin-bottom: 2rem;
  text-align: center;
`;

const StepsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Step = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  max-width: 250px;
  margin: 0 1rem;

  @media (max-width: 768px) {
    margin-bottom: 2rem;
  }
`;

const Icon = styled.img`
  width: 60px;
  height: 60px;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
  }
`;

const StepDescription = styled.p`
  font-size: 1rem;
  color: #555555;
`;

const UpcycleProcess = () => {
  return (
    <Container>
      <Header>How the Upcycle Process Works</Header>
      <StepsContainer>
        <Step>
          <Icon src={search} alt="Search Icon" />
          <StepDescription>
            We'll use the magic of AI to understand what you would like to upcycle. This helps us understand how much you can earn on each device.
          </StepDescription>
        </Step>
        <Step>
          <Icon src={send} alt="Send Icon" />
          <StepDescription>
            Ship your devices to us with a free shipping label. First thing that happens when your devices arrive is we clear your data to ensure we can upcycle your devices.
          </StepDescription>
        </Step>
        <Step>
          <Icon src={money} alt="Money Icon" />
          <StepDescription>
            That's it! You'll receive a payout through your preferred payment method within 5 days of receiving your items.
          </StepDescription>
        </Step>
      </StepsContainer>
    </Container>
  );
};

export default UpcycleProcess;



