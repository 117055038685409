import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import biggood from '../assets/biggood.jpg'
import recycle from '../assets/techlandingpage.png'
import LandingUpcycle from './LandingUpcycle';
import testpage from '../assets/testpage4.svg'

const MainContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #385b4f;
`;

const ImageOverlay = styled.div`
   position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Ensure video doesn't overflow the container */
  display: flex;
  justify-content: center;
  align-items: center;

  /* Gradient shadow overlay */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom, 
      rgba(0, 0, 0, 0), /* Adjust color and opacity */
      rgba(0, 0, 0, 0.3) /* Transparent at the bottom */
    );
    z-index: 1; /* Ensure the gradient is above the video */
  }

  /* Ensure child content (like video) is below the gradient overlay */
  z-index: 0; 
`;

const Video = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ContentContainer = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
  color: white;
`;

const Heading = styled.h1`
  font-size: 3rem;
  margin-bottom: 3px;
  color: #f8f6ea;

  @media (max-width: 768px) {
    font-size: 3rem; 
    max-width: 90%; 
    margin: 0 auto; 
  }
`;



const Subheading = styled.p`
  margin-top: 1px;
  font-size: 1.5rem;
  color: #f8f6ea;
`;

const RecycleButton = styled.button`
  padding: 10px 20px;
  background-color: #d88a59;
  font-size: 1.3rem;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #C0784D;
  }
`;

const ResponsiveMain = () => {

    const navigate = useNavigate();

  const handleToRecycle = () => {
    // Navigate back to the home page
    navigate('/upcycle');
  };


  return (
    <MainContainer>
     
      <ImageOverlay>
        <Video src={testpage} />
        </ImageOverlay>
       
      <ContentContainer>
        <Heading>The safest place to recycle your tech.</Heading>
        <Subheading>Declutter your space sustainably within minutes.</Subheading>
        <RecycleButton onClick={handleToRecycle}>Start Recycling</RecycleButton>
      </ContentContainer>
    </MainContainer>
  );
};

export default ResponsiveMain;



