import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 90%; // Make it take full width
  margin: 0 auto;
  background-color: #f5f5f5;
  border-radius: 25px;
  padding: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 0.5rem;
  }

  @media (max-width: 480px) {
    padding: 0.4rem;
  }
`;

const StyledInput = styled.input`
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 0.75rem;
  font-size: 1rem;
  border-radius: 25px 0 0 25px;
  background-color: transparent;
`;

const InputButton = styled.button`
  background-color: #4caf4c;
  color: white;
  border: none;
  outline: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 0 25px 25px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #004d40;
  }
`;

const Description = styled.p`
  font-size: 0.85rem;
  white-space: normal; // Allow text to wrap normally
  margin-top: 1rem; // Add margin to separate from input container
  text-align: center; // Center align text
  padding: 0 1rem; // Add padding to fit text on smaller screens
  box-sizing: border-box; // Ensure padding does not affect overall width

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }

  @media (max-width: 480px) {
    font-size: 0.75rem;
    padding: 0 0.5rem; // Reduce padding on very small screens
  }
`;

function LandingUpcycle() {
  const [prompt, setPrompt] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [promptError, setPromptError] = useState('');
  const [searchQuery, setSearchQuery] = useState(false);
  const [devices, setDevices] = useState([]);
  const navigate = useNavigate();


  function getCookie(name) {
    const cookieValue = document.cookie.split('; ')
      .find(cookie => cookie.startsWith(name + '='))
      ?.split('=')[1];
    return cookieValue ? decodeURIComponent(cookieValue) : null;
  }

  function handlePrompt() {
    navigate(`/upcycle?data=${prompt}`);
        
  }

  return (
    <>
      <InputContainer>
        <StyledInput
          type="text"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="You can say something like 'a laptop from 2017'"
        />
        <InputButton onClick={handlePrompt}>Submit</InputButton>
      </InputContainer>
      <Description>
        We'll use AI to find what you would like to upcycle - <i>providing the item's brand, model, or year is helpful to refine results.</i>
      </Description>
    </>
  );
}

export default LandingUpcycle;

