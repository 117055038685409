import React from 'react'
import Main from './Main'
import Counter from './Counter'
import DeviceLife from './DeviceLifeLanding'
import IntroVideo from './IntroVideo'
import DataPrivacyLanding from './RaccoonData'
import Why from './Why'
import "../styles/Home.css"
import Hero from '../components/Hero'
import LandingStatComponent from './LandingStats'
import { styled } from 'styled-components'

const HomeDiv = styled.div`
 background-color: #f8f6ea;
`;

function Home(){
    return (
        <HomeDiv>
            <Main />
            <DataPrivacyLanding /> 
                <Hero />
                <Why />
                <Counter />
                <LandingStatComponent />
                <DeviceLife /> 
                            
        </HomeDiv>
    )
}

export default Home