import styled from "styled-components"

export const QueryContainer = styled.div`
    max-width: 800px;
    margin: 50px auto 0; /* Adjust the margin top to move the entire component down */
    padding: 20px;
    
`;


export const QueryInput = styled.input`
    width: 100%;
    padding: 10px;
    font-size: 16px;
    margin-bottom: 20px; /* Adjust the margin bottom to move the search bar down */
    border: 2px solid #ccc;
    border-radius: 5px;
`;

export const QueriedItemsContainer = styled.div`
    margin-bottom: 20px;
`;

export const QuerySectionTitle = styled.h2`
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
`;

export const QueryCategoryContainer = styled.div`
    margin-bottom: 40px;
`;

export const QueryItemsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Adjust the number of columns */
    gap: 20px;
    margin-top: 20px; /* Add margin-top for better separation from the title */
`;


export const QueryItem = styled.div`
    background: #f8f8f8;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
        background: #e0e0e0;
    }
`;
