import React from 'react'
import Recycler from "../assets/landingrecycler5.svg"
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  text-align: center;
  @media (max-width: 768px) {
    /* Stack elements vertically on mobile */
    padding: 20px; /* Reduce padding for smaller screens */
  }
`;

const Description = styled.h2`
  font-size: 46px;
  width: 100%;
  margin: 0;
  color: #444;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 36px; /* Reduce font size on mobile */
  }
`;

const Image = styled.img`
  width: 100%;
  max-height: 400px; /* Adjust max-width as needed */
  margin-bottom: 30px;
`;

const RecycleButton = styled.button`
  padding: 15px 30px;
  font-size: 1.2rem;
  background-color: #d88a59;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #C0784D;
  }
`;


const DeviceLife = () => {

    const navigate = useNavigate();

    const handleToRecycle = () => {
      // Navigate back to the home page
      navigate('/upcycle');
    };
  return (
    <SectionContainer>
     
      <Description>Let's get rid of electronics that deserve to be recycled.</Description>
      <RecycleButton onClick={handleToRecycle}>Recycle now</RecycleButton>
    </SectionContainer>
  );
};

export default DeviceLife;