import React from 'react'
import styled from 'styled-components'
import raccoons from "../assets/RaccoonData4.png"
import datachip from '../assets/dataraccoon.svg'
import { useNavigate } from 'react-router-dom';


const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  margin-bottom: 0px;
  margin-top: 0px;
  background-color: #f8f6ea;
 
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 500px;  /* Limit the maximum width to avoid extreme stretching */
  height: 100%;
  max-height: 400px; 
  margin-right: 2rem;
  border-radius: 10px;

  @media (max-width: 768px) {
    height: 70%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }
`;


const TextContainer = styled.div`
  max-width: 50%;
  

  @media (max-width: 768px) {
    max-width: 100%;
    text-align: center;
  }
`;

const Heading = styled.h1`
  font-size: 3rem;
  color: #385b4f;
  margin-bottom: 1rem;
`;

const Text = styled.p`
  font-size: 1.2rem;
  color: #385b4f;
  line-height: 1.5;
`;

const LearnMoreButton = styled.button`
  padding: 15px 30px;
  font-size: 1.2rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #45a049;
  }
`;

function DataPrivacyLanding(){

    const navigate = useNavigate();

    const handleToAbout = () => {
      // Navigate back to the home page
      navigate('/about');
    };

    return(
        <Container>
      <Image src={datachip} alt="Terraforge - Electronics Recycling" />
      <TextContainer>
        <Heading>Declutter your space and keep your data safe.</Heading>
        <Text>
         We make it easier than ever to recycle your electronics responsibly. Our mission is to safeguard your data and help you declutter your space sustainably.</Text>
      </TextContainer>
    </Container>
    )
}

export default DataPrivacyLanding;