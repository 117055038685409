
import styled from "styled-components";

export const Body = styled.div`
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EFEFEF;
`
export const CardImage = styled.div`
    position: relative;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background: #fff;
    padding: 3px;
`

export const CardWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Adjust the gap between cards */
    justify-content: center;
`;

export const CardImg = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
    
`
export const SlideContent = styled.div`
    margin: 0 40px;
`
export const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 14px;
`
export const ImgContent = styled.div`
    position: relative;
    row-gap: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 14px;
`
export const Overlay = styled.span`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: whitesmoke;
    border-radius: 25px 25px 0 25px;
`

export const Card = styled.div`
    width: 320px;
    border-radius: 25px;
    background-color: #FFF;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s ease;
    
    &:hover {
        background-color: #f0f0f0;
    }
`
export const CardName = styled.h2`
    font-size: 18px;
    font-weight: 500;
`
export const CardDescription = styled.p`
    font-size: 14px;
    color: #707070;
    text-align: center;
`

export const Button = styled.button`
    border: none;
    font-size: 16px;
    color: #333;  // Darker text color
    padding: 8px 16px;
    background-color: #f8f6ea;
    border-radius: 6px;
    margin: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
    
    &:hover {
        background-color: #d88a59;
    }
`


export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 14px;
`;

export const LogoImg = styled.img`
    max-width: 100%; /* Limiting maximum width */
    max-height: 100%; /* Limiting maximum height */
    width: auto;
    height: auto;
    object-fit: contain; /* Ensure the entire image is visible within the container */
`;

export const LogoImage = styled.div`
    position: relative;
    height: 150px; /* Adjust as needed */
    width: 150px; /* Adjust as needed */
    border-radius: 50%;
    background: #fff;
    padding: 3px;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    overflow: hidden;
`;
