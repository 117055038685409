import React from "react";
import "../styles/Footer.css"
import facebook from "../assets/facebook.svg"
import instagram from "../assets/insta.svg"
import twitter from "../assets/twitter.svg"

function Footer(){


    return (
        <div className="footer">
            <div className="sb_footer section_padding">
                <div className="sb_footer-links">
                    <div className="sb_footer-links_div">
                        <h4>Company</h4>
                        <a href="/">
                            <p>Home</p>
                        </a>
                        <a href="/recycle">
                            <p>Recycle</p>
                        </a>
                        <a href="/contact">
                            <p>Contact</p>
                        </a>
                    </div>
                    <div className="sb_footer-links_div">
                        <h4>Resources</h4>
                        <a href="/blog">
                            <p>Blog</p>
                        </a>
                        <a href="/about">
                            <p>About</p>
                        </a>
                    </div>
                    <div className="sb_footer-links_div"></div>
                </div>

                <hr></hr>

                <div className="sb_footer-below">
                    <div className="sb_footer-copyright">
                        <p>
                            @{new Date().getFullYear()} Basile. All rights reserved.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer