import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 40px;
  max-width: 1200px; /* Set a max-width to make it narrower */
  margin: 0 auto;
   /* Center the component horizontally */

  @media (max-width: 768px) {
    flex-direction: column; /* Stack elements vertically on mobile */
    padding: 20px; /* Reduce padding for smaller screens */
  }
`;

const LeftSection = styled.div`
  flex: 1;
  text-align: center;
  margin-bottom: 20px; /* Add margin for spacing on mobile */

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const Statistic = styled.div`
  font-size: 140px;
  font-weight: bold;
  color: #385b4f;

  @media (max-width: 768px) {
    font-size: 80px; /* Reduce font size on mobile */
  }
`;

const RightSection = styled.div`
  flex: 2;
  padding-left: 30px;

  @media (max-width: 768px) {
    padding-left: 0;
    text-align: center; /* Center text on mobile */
  }
`;

const Header = styled.h2`
  font-size: 36px;
  margin: 0;
  color: #444;

  @media (max-width: 768px) {
    font-size: 36px; /* Reduce font size on mobile */
  }
`;

const Description = styled.p`
  font-size: 24px;
  color: #666;
  margin-top: 15px;

  @media (max-width: 768px) {
    font-size: 18px; /* Reduce font size on mobile */
  }
`;

const SlideToggle = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center; /* Center slide toggle on mobile */

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

const SlideButton = styled.button`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: none;
  margin-right: 12px;
  background-color: ${({ $active }) => ($active ? '#444' : '#ccc')};
  cursor: pointer;
  
  &:hover {
    background-color: #444;
  }

  @media (max-width: 768px) {
    width: 6px;
    height: 10px; /* Slightly reduce size on mobile */
    margin-right: 6px; /* Reduce the margin on mobile to avoid squishing */
  }
`;





const LandingStatComponent = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
  
    const slides = [
      {
        statistic: "85%",
        header: "Devices don't end up where they should.",
        description: "Only about twenty percent of electronics end up recycled correctly. We want to help you make sure you have a fast and convenient way to recycle your electronics."
      },
      {
        statistic: "40%",
        header: "Carbon emmissions reduction.",
        description: "Extending a devices life by two years reduces their carbon emmissions by over fourty percent."
      },
      {
        statistic: "100%",
        header: "Data security when you recycle.",
        description: "When you recycle with Basile, we ensure your data is handled correctly. We provide both a software wipe and a physical data shred when your devices arrive at our recyclers."
      }
    ];
  
    const handleSlideChange = (index) => {
      setCurrentSlide(index);
    };
  
    return (
      <Container>
        <LeftSection>
          <Statistic>{slides[currentSlide].statistic}</Statistic>
        </LeftSection>
        <RightSection>
          <Header>{slides[currentSlide].header}</Header>
          <Description>{slides[currentSlide].description}</Description>
          <SlideToggle>
          {slides.map((_, index) => (
            <SlideButton
            key={index}
            $active={index === currentSlide} // Use $active here
            onClick={() => handleSlideChange(index)}
            />
            ))}
          </SlideToggle>
        </RightSection>
      </Container>
    );
  };
  
  export default LandingStatComponent;