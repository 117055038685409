import React from "react";
import styled from "styled-components";
import box from "../assets/box.png"; // Import icons (replace with your icon components)
import MailIcon from "../assets/002-email.png";
import RecycleIcon from "../assets/001-recycle.png";
import RaccoonBox from '../assets/processBoxRaccoon.png'
import Raccoon from '../assets/racoonbox.png'
import RaccoonMail from '../assets/raccoonmail.png'
import RaccoonCarry from '../assets/Raccooncarrying1.png'
import RaccoonRecycle from '../assets/Raccoonrecycle2.png'
import raccoondefender from '../assets/Raccoondefender1.png'
import flying from '../assets/Raccoonflying.png'

const Icon = styled.div`
  width: 180px; /* Adjust width as needed */
  height: 180px; /* Adjust height as needed */
  background-image: url(${RaccoonCarry});
  background-size: cover;
  background-position: center; /* Center the image within the div */
  background-repeat: no-repeat; /* Prevent image from repeating */
  margin: 0 auto 10px auto; /* Center horizontally and add margin below */
`;

const Mail = styled.div`
  width: 180px; /* Adjust width as needed */
  height: 180px; /* Adjust height as needed */
  background-image: url(${raccoondefender});
  background-size: cover;
  background-position: center; /* Center the image within the div */
  background-repeat: no-repeat; /* Prevent image from repeating */
  margin: 0 auto 10px auto; /* Center horizontally and add margin below */
`;

const Box = styled.div`
  width: 180px; /* Adjust width as needed */
  height: 180px; /* Adjust height as needed */
  background-image: url(${RaccoonRecycle});
  background-size: cover;
  background-position: center; /* Center the image within the div */
  background-repeat: no-repeat; /* Prevent image from repeating */
  margin: 0 auto 10px auto; /* Center horizontally and add margin below */
`;

// Features Section
const FeaturesSection = styled.section`
  padding: 60px 20px;
  background-color: #f8f6ea;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const FeatureCard = styled.div`
  background-color: #385b4f;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px;
  width: 300px;
  text-align: center;
`;

const FeatureHeading = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #f8f6ea;
`;

const FeatureDescription = styled.p`
  font-size: 1rem;
  color: #f8f6ea;
`;



const LandingPage = () => {
  return (
    <div>
   

      <FeaturesSection>
      <FeatureCard>
  <Icon />
  <FeatureHeading>Effortless Electronics Recycling</FeatureHeading>
  <FeatureDescription> Just tell us what you want to recycle—whether it's old laptops, smartphones, and we'll take care of everything. </FeatureDescription>
</FeatureCard>
<FeatureCard>
  <Mail />
  <FeatureHeading>Defending your Data Privacy</FeatureHeading>
  <FeatureDescription>We treat your data with care, all devices are securely wiped before processing to ensure your data is never compromised. 
</FeatureDescription>
</FeatureCard>
<FeatureCard>
  <Box />
  <FeatureHeading>Sustainable Recycling Solutions</FeatureHeading>
  <FeatureDescription> Our smarter recycling solutions help reduce electronic waste and promote the responsible disposal of old electronics.
</FeatureDescription>
</FeatureCard>

      </FeaturesSection>
      

    </div>
  );
};

export default LandingPage;
