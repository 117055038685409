import React, {useState, useEffect} from 'react'
import ReactGA from 'react-ga4';
import axios from 'axios'
import box from "../assets/box.png"
import shield from "../assets/shield.png";
import notFound from "../assets/notfound.png"
import finalForm from '../assets/finalform.svg'
import {CardWrapper, ContentWrapper, CardImage, CardImg, SlideContent, CardContent, ImgContent, Overlay, Card, CardName, CardDescription, Button} from "../styles/about.styles"
import {
    QueryContainer,
    QueryInput,
    QueriedItemsContainer,
    QuerySectionTitle,
    QueryCategoryContainer,
    QueryItemsContainer,
    QueryItem} from "../styles/RecycleFormQuery.styles"
    import styled from 'styled-components';
    import loadingImage from "../assets/001-recycle.png"
    import MailIcon from "../assets/002-email.png"
import RecycleIcon from "../assets/001-recycle.png"
import Micro from "../assets/micro.svg"
import Midi from "../assets/midi.svg"
import Macro from "../assets/macro.svg"
import Recycler from "../assets/landingrecycler.svg"
import UpcycleProcess from './Upcycleprocess';
import DeviceList from './CommonDevices';
import { useLocation } from 'react-router-dom';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import CheckoutRaccoon from '../assets/CheckoutRaccoon.png'

    const Body = styled.div`
    margin-top: 90px;
    background-color: #f8f6ea;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Align content at the top */
    padding: 10px; /* Add padding for spacing */
    overflow-y: auto; /* Enable vertical scrolling if needed */
`;

const DeviceButton = styled.button`
  padding: 5px 10px;
  background-color: #d88a59;
  font-size: 1.1rem;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #C0784D;
  }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center; /* Center-align the content */
    margin-top: 10px;
    margin-bottom: 10px;
     /* Add some space above the loading message */
`;

const LoadingImage = styled.img`
    width: 150px; /* Adjust the width of the image as needed */
`;

const LoadingText = styled.p`
    font-size: 16px;
    color: #666;
    margin-top: 10px; /* Add some space between the image and the text */
`;

const Spinner = styled.div`
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #333;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
`;

const QuoteSpinner = styled.div`
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #333;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    animation: spin 1s linear infinite;

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
`;

const HeadingContainer = styled.div`
  text-align: center;
  margin-bottom: 1px;
  margin-top: 10px;
  
  @media (max-width: 768px) {
    width: 100%;
    
  }

`;

const PromptText = styled.p`
  @media (max-width: 768px) {
    width: 100%;
    
  }
`



const Title = styled.h1`
    font-size: 38px; /* Adjust the font size as needed */
    margin-bottom: 10px; /* Add some space below the h1 */
    color: #385b4f;
`;

const Question = styled.h1`
    font-size: 24px; /* Adjust the font size as needed */
    margin-bottom: 10px; /* Add some space below the h1 */
    color: #385b4f;
`;

const TitleH2 = styled.h2`
    font-size: 24px; /* Adjust the font size as needed */
    color: #385b4f;
`;

const H1 = styled.h1`
    font-size: 18px; /* Adjust the font size as needed */
    margin-bottom: 10px; /* Add some space below the h1 */
    color: #385b4f;
`;

const H2 = styled.h2`
    font-size: 14px; /* Adjust the font size as needed */
    color: #385b4f;

`;

const Ital = styled.p`
    font-size: 14px; /* Adjust the font size as needed */
    color: #666; /* Set the color to a subdued gray */
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  background-color: #f5f5f5;
  border-radius: 25px;
  padding: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const StyledInput = styled.input`
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 0.75rem;
  font-size: 1rem;
  border-radius: 25px 0 0 25px;
  background-color: transparent;
`;

const InputButton = styled.button`
  background-color: #d88a59;
  color: white;
  border: none;
  outline: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 0 25px 25px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #C0784D;
  }
`;

const DeviceContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center align device cards */
  
`;

const DeviceCard = styled.div`
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
    padding: 20px;
    width: 300px;
    color: #385b4f;
    transition: transform 0.3s ease;
    cursor: pointer;
    border: 2px solid transparent; /* Add transparent border by default */

    &:hover {
        transform: translateY(-5px);
    }

    ${({ $isselected }) =>
        $isselected &&
        `
        border: 2px solid #d88a59
    `}
`;


const DeviceDetail = styled.p`
    font-family: 'Roboto', sans-serif; /* Use a modern and sleek font */
    font-size: 16px; /* Optimal font size for readability */
    color: #333; /* Dark gray color for high contrast */
    margin-bottom: 8px; /* Consistent margin for spacing */
`;

const BoldDetail = styled.p`
    font-family: 'Roboto', sans-serif;
    font-weight: bold; /* Use a modern and sleek font */
    font-size: 18px; /* Optimal font size for readability */
    color: #333; /* Dark gray color for high contrast */
    margin-bottom: 8px; /* Consistent margin for spacing */
`;

const RecycleDetail = styled.p`
    font-family: 'Roboto', sans-serif;
    font-weight: bold; /* Use a modern and sleek font */
    font-size: 18px; /* Optimal font size for readability */
    color: #333; /* Dark gray color for high contrast */
    margin-top: 2px;
    margin-bottom: 2px; /* Consistent margin for spacing */
    padding: 0px;
`;

const RecycleInfo = styled.p`
    font-family: 'Roboto', sans-serif; /* Use a modern and sleek font */
    font-size: 16px; /* Optimal font size for readability */
    color: #333; /* Dark gray color for high contrast */
    margin-top: 4px;
    margin-bottom: 8px; /* Consistent margin for spacing */
`;

const StorageOptions = styled.ul`
    margin-top: 8px; /* Add some space between device details and storage options */
    padding-left: 20px;
    list-style-type: none; /* Remove bullet points */
`;

const StorageOptionItem = styled.li`
    font-family: 'Roboto', sans-serif; /* Use the same font as device details */
    font-size: 14px; /* Slightly smaller font size for storage options */
    color: #666; /* Light gray color for differentiation */
    margin-bottom: 4px; /* Consistent margin for spacing */
`;

const OptionContainer = styled.div`
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center; /* Center align device cards */
`
const OptionCard = styled.div`
     background-color: #385b4f;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
    padding: 20px;
    width: 300px;
    transition: transform 0.3s ease;
    cursor: pointer;
    border: 2px solid transparent; /* Add transparent border by default */

    &:hover {
        transform: translateY(-5px);
    }

    ${({ $isselected }) =>
        $isselected &&
        `
        border: 2px solid blue; /* Change border color to blue when selected */
    `}
`
const OptionName = styled.p`
    font-family: 'Poppins', sans-serif; /* Use Poppins font */
    font-size: 24px; /* Increase font size */
    color: #f8f6ea;
    margin-bottom: 10px;
    font-weight: bold;
`

const OptionDetail = styled.li`
    text-align: left;
    font-size: 14px;
    color: #f8f6ea;
    margin-top: 5px;
`
const OptionCardsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
`;
const InterestContainer = styled.div`
  display: flex;
  flex-direction: column; /* Render interests in a single column */
  align-items: center; /* Center-align interests */
  padding: 0; /* Ensure no extra padding */

  /* Add media query for mobile responsiveness */
  @media (max-width: 768px) {
    padding: 10px; /* Add some padding for better spacing on mobile */
  }
`;

const QuestionContainer = styled.div`
  text-align: center; /* Center-align the content */
  margin-bottom: 10px; /* Adjust bottom margin for spacing */
  margin-top: 20px; /* Add some space between the headings and the input field */
`;

const InterestCard = styled.div`
  background-color: #fff; 
  border-radius: 20px; /* Larger border radius for a sleek look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
  margin: 20px; /* Spacing between cards */
  padding: 20px; /* Padding inside the card */
  width: 100%; /* Make it responsive */
  max-width: 400px; /* Set maximum width for consistency */
  cursor: pointer;
  transition: transform 0.3s ease;
  border: 2px solid transparent; /* Transparent border by default */

  &:hover {
    transform: translateY(-5px); /* Lift card slightly on hover */
  }

  ${({ $isselected }) =>
    $isselected &&
    `
    border: 2px solid #4070f4; /* Blue border when selected */
  `}

  /* Add media query for mobile responsiveness */
  @media (max-width: 768px) {
    width: 90%; /* Adjust width for mobile */
    margin: 10px; /* Reduce margin for better spacing on mobile */
  }
`;
const InterestName = styled.p`
    font-family: 'Poppins', sans-serif; /* Use Poppins font */
    font-size: 20px; /* Slightly larger font size */
    color: #333;
    margin-bottom: 10px; /* Spacing between name and detail */
    font-weight: bold;
`;

const InterestDetail = styled.p`
    font-size: 16px; /* Font size for option details */
    color: #666;
    margin-top: 5px; /* Spacing between details */
`;

const SummaryContainer = styled.div`
  display: flex;
  justify-content: flex-start; /* Align items at the start with reduced space */
  align-items: flex-start; /* Align items to the top of the container */
  padding: 20px; /* Adjust padding as needed */
  width: 100%; /* Ensure the container takes full width */
  box-sizing: border-box; /* Include padding in the element's total width and height */

  @media (max-width: 768px) {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: center; /* Center items horizontally */
    padding: 10px; /* Adjust padding for smaller screens */
  }
`;




const SummaryHeading = styled.h1`
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
`;

const DeviceSummary = styled.div`
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 90%; /* Full width by default */

  h2 {
    font-size: 24px;
    margin-bottom: 10px;
    border-bottom: 2px solid #385b4f; /* Underline color */
    padding-bottom: 5px; /* Space between text and underline */
  }

  dl {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
  }

  dt {
    font-weight: bold;
    margin-right: 10px;
    min-width: 150px; /* Set a minimum width for terms */
  }

  dd {
    margin: 0;
    margin-bottom: 10px;
    font-size: 16px;
  }
`;


const ShippingForm = styled.form`
  background-color: #385b4f; /* Light grey background for a modern look */
  border-radius: 12px; /* Slightly more rounded corners */
  padding: 24px; /* Increased padding for a spacious feel */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Larger shadow for depth */
  width: 35%; /* Adjusted width */
  margin-left: 10px; /* Space between ShippingForm and DeviceSummary/StyledList */
  display: flex;
  flex-direction: column;
  gap: 16px; /* Space between form elements */

  @media (max-width: 768px) {
    width: 90%;
    margin-left: 0;
    margin-bottom: 20px;
  }

  h1 {
    font-size: 24px;
    color: #f8f6ea;
  }

  h2 {
    font-size: 18px;
    color: #f8f6ea;
    margin-bottom: 0px;
  }
  p {
    margin-top: 0px;
    font-size: 14px;
    color: #f8f6ea;
  }

  label {
    font-size: 14px;
    font-weight: 500; /* Slightly bolder text */
    color: #f8f6ea; /* Dark grey color for text */
    margin-bottom: 8px; /* Reduced margin for a tighter look */
    display: block;
  }

  input[type="text"] {
    width: 90%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc; /* Light border for a subtle tech feel */
    border-radius: 8px; /* Rounded corners for inputs */
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1); /* Inner shadow for depth */
    margin-bottom: 16px; /* Increased margin for spacing */
    transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for interactions */


    &:focus {
      border-color: #007bff; /* Highlight color on focus */
      box-shadow: 0 0 8px rgba(0, 123, 255, 0.2); /* Shadow on focus */
      outline: none; /* Remove default focus outline */
    }
  }

  input[type="password"] {
    width: 90%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc; /* Light border for a subtle tech feel */
    border-radius: 8px; /* Rounded corners for inputs */
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1); /* Inner shadow for depth */
    margin-bottom: 16px; /* Increased margin for spacing */
    transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for interactions */


    &:focus {
      border-color: #007bff; /* Highlight color on focus */
      box-shadow: 0 0 8px rgba(0, 123, 255, 0.2); /* Shadow on focus */
      outline: none; /* Remove default focus outline */
    }
  }

  button {
    background-color: #d88a59; /* Primary tech color */
    color: #fff;
    border: none;
    padding: 14px 24px; /* Larger padding for better click targets */
    font-size: 16px;
    font-weight: 600; /* Slightly bolder text */
    border-radius: 8px; /* Rounded corners for button */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition for hover effects */

    &:hover {
      background-color: #C0784D; /* Darker shade for hover */
      transform: translateY(-2px); /* Slight lift effect on hover */
    }

    &:active {
      background-color: #004494; /* Even darker shade for active state */
      transform: translateY(0); /* Reset the lift effect */
    }
  }
`;

const ProcessContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 2px;
    margin-bottom: 20px; /* Adjust margin as needed */

    @media (max-width: 768px) {
        flex-direction: column; /* Stack elements vertically on smaller screens */
    }
`;

const ProcessStep = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20px;
`;

const Icon = styled.div`
    width: 50px; /* Adjust width as needed */
    height: 50px; /* Adjust height as needed */
    background-image: url(${box});
    background-size: cover;
    margin-bottom: 10px; /* Adjust margin as needed */
`;

const Mail = styled.div`
    width: 50px; /* Adjust width as needed */
    height: 50px; /* Adjust height as needed */
    background-image: url(${MailIcon});
    background-size: cover;
    margin-bottom: 10px; /* Adjust margin as needed */
`;

const Recycle = styled.div`
    width: 50px; /* Adjust width as needed */
    height: 50px; /* Adjust height as needed */
    background-image: url(${RecycleIcon});
    background-size: cover;
    margin-bottom: 10px; /* Adjust margin as needed */
`;

const StepDescription = styled.p`
    font-size: 16px; /* Adjust font size as needed */
    color: #555; /* Adjust font color as needed */
    text-align: center;
    line-height: 1.5;
`;

const Separator = styled.div`
    width: 100%;
    height: 0;
    border-top: 1px solid #e0e0e0; /* Subtle gray color */
    margin: 20px 0; /* Adjust margin as needed */
`;

const SummarySeparator = styled.div`
    width: 100%;
    height: 0;
    border-top: 1px solid #e0e0e0; /* Subtle gray color */
    margin: 20px 0; /* Adjust margin as needed */
`;

const RecycleH1 = styled.h1`
    font-size: 18px; /* Adjust the font size as needed */
    margin-bottom: 10px;
    color: #6bb37e; /* Add some space below the h1 */
`;

const Checkbox = styled.input`
    margin-right: 10px;
`;

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

const LoginDiv = styled.div`
margin-top: 100px;
margin-bottom: 100px;
  text-align: center;
  
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column; /* Align items in a column */
  max-width: 400px; /* Control maximum width */
  width: 90%; /* Occupy 90% of the available width */
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  margin: 20px auto; /* Center form and add top margin */
  
  @media (max-width: 768px) {
    padding: 15px; /* Adjust padding for mobile */
  }
`;

const LoginField = styled.div`
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 15px; /* Adjust margin-bottom for mobile */
  }
`;

const LoginLabel = styled.label`
  font-weight: bold;
  display: block;
  margin-bottom: 5px; /* Add some space below the label */

  @media (max-width: 768px) {
    font-size: 14px; /* Adjust font size for mobile */
    margin-bottom: 3px; /* Adjust margin-bottom for mobile */
  }
`;

const LoginInputField = styled.input`
  width: 90%; /* Full width */
  padding: 12px; /* Increased padding for better aesthetics */
  border: 1px solid #ccc;
  border-radius: 4px;

  @media (max-width: 768px) {
    padding: 10px; /* Adjust padding for mobile */
    font-size: 14px; /* Adjust font size for mobile */
  }
`;

const LoginSubmitButton = styled.button`
  padding: 12px 20px; /* Adjust padding for a better button size */
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Add a smooth transition effect */
  
  &:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }

  @media (max-width: 768px) {
    padding: 10px 15px; /* Adjust padding for mobile */
    font-size: 14px; /* Adjust font size for mobile */
  }
`;

const LoggedInHeader = styled.div`
    text-align: center;
    margin-bottom: 20px;
`;

const ThankYouMessage = styled.p`
    font-size: 1.2em;
    margin-bottom: 10px;
`;

const WrapUpMessage = styled.h2`
    font-size: 1.5em;
    margin-bottom: 20px;
`;

const StyledList = styled.ul`
  
  flex: 1;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0; /* Remove default padding */
  list-style: none;
  width: 90%;

  @media (max-width: 768px) {
    width: 80%;
    margin-left: 0;
    margin-bottom: 20px;
  }
`;

const DeviceAndListContainer = styled.div`
  background-color: #f8f6ea;
  display: flex;
  flex-direction: column;
  width: 60%; /* Width adjusted to fit within the summary container */
  margin-right: 20px; /* Added margin to give space between the container and the ShippingForm */

  @media (max-width: 768px) {
    width: 100%; /* Full width on smaller screens */
    margin-right: 0; /* Remove margin on smaller screens */
  }
`;


const StyledListItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const IconWrapper = styled.div`
  flex-shrink: 0;
  margin-right: 10px;
`;

const WhatsNextIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const Content = styled.div`
  flex-grow: 1;
`;

const WhatsNextTitle = styled.strong`
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
`;

const WhatsNextShield = styled.strong`
  color: #0cc0df;
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Description = styled.p`
  margin: 0;
`;

const SummaryIcon = styled.img`
  width: 60%;
  
`;

const SummaryWrapper = styled.div`
 display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const ErrorMessage = styled.div`
    color: #ff0000; /* Red color for error messages */
    font-size: 14px; /* Adjust font size as needed */
    margin-top: 5px; /* Add some margin to separate it from other elements */
`;

const StyledSpan = styled.span`
  cursor: pointer; /* Change cursor to pointer to indicate interactivity */
  color: blue; /* Set color to blue to make it stand out as a clickable element */
  text-decoration: underline; /* Add underline to make it look like a link */
  /* Optionally, add more styles to customize the appearance further */
`;

const GreenFont = styled.span`
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 14px;
    color: #4CAF50;
    margin-bottom: 0px;
`

const PopupContainer = styled.div`
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: ${props => (props.isVisible ? 'block' : 'none')};
  top: 200px;  /* Adjust as needed */
  left: 600px; /* Adjust as needed */

  /* Add media query for mobile responsiveness */
  @media (max-width: 768px) {
    width: 90%; /* Adjust width for mobile */
    left: 50%; /* Center horizontally */
    top: 150px; /* Adjust top position for mobile */
    transform: translateX(-50%); /* Center horizontally */
  }
`;

const HowItWorksLink = styled.span`
  color: blue;
  cursor: pointer;
  text-decoration: underline;
`;

const ToggleButton = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
`;

const CollapsibleContent = styled.div`
  max-height: ${({ isOpen }) => (isOpen ? '1000px' : '0')};
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
`;

const CheckoutContainer = styled.div`
  display: flex;
  flex-direction: row; /* Default to row */
  justify-content: space-between;
  margin: 20px;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack on smaller screens */
  }
`;

const ResponsiveImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const DeviceCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align items horizontally */
  gap: 8px; /* Adjust the spacing between the card and button */
`;

const ReferralContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;

  @media (min-width: 769px) {
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }
`;

const ReferralLabel = styled.label`
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  color: #333;
  margin-bottom: 10px;
  display: block;
  width: 100%; /* Takes full width on mobile */

  @media (min-width: 769px) {
    margin-bottom: 0;
    width: auto;
  }
`;

const ReferralInput = styled.input`
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;
  width: 100%; /* Takes full width on mobile */

  &:focus {
    border-color: #007BFF;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }

  @media (min-width: 769px) {
    flex: 1;
    width: auto;
  }
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  color: #fff;
  background-color: #007BFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%; /* Full width on mobile */
  margin-top: 10px;

  &:hover {
    background-color: #0056b3;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }

  @media (min-width: 769px) {
    width: auto;
    margin-top: 0;
  }
`;

const ReferralErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px; /* Space between the input and button */
`;

const ReferralError = styled.div`
  color: red;
  font-size: 0.875rem;
  margin-top: 5px;
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ToggleLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ToggleInput = styled.input`
  display: none;
`;

const ToggleSlider = styled.span`
  position: relative;
  width: 50px;
  height: 24px;
  background-color: ${({ isChecked }) => (isChecked ? '#4caf50' : '#ccc')};
  border-radius: 50px;
  transition: background-color 0.3s;
  
  &::before {
    content: '';
    position: absolute;
    top: 2px;
    left: ${({ isChecked }) => (isChecked ? '26px' : '2px')};
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    transition: left 0.3s;
  }
`;

const TextLabel = styled.p`
  margin-left: 10px;
  font-size: 16px;
  color: #333;
`;

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

const client = axios.create({
  baseURL: "http://127.0.0.1:8000"
});


    function UpcycleForm({LoggedIn,userInfo, handleBoxRequest}) {
        const [prompt, setPrompt] = useState("");
        const [devices, setDevices] = useState([]);
        const [loading, setLoading] = useState(false);
        const [selectedDevices, setSelectedDevices] = useState([]);
        const [pageTwo, setPageTwo] = useState(false)
        const [condition, setCondition] = useState('')
        const [conditionSelected, setConditionSelected] = useState(false)
        const [pageCount, setPageCount] = useState(1)
        const [interest, setInterest] = useState('')
        const [shippingDetails, setShippingDetails] = useState({
            fullName: '',
            address: '',
            city: '',
            state: '',
            country: '',
            postalCode: ''
        });
        const [selectedOption, setSelectedOption] = useState('ownBox');
        const [email, setEmail] = useState('');
        const [username, setUsername] = useState('');
        const [password, setPassword] = useState('');
        const [verifyPassword, setVerifyPassword] = useState('');
        const [currentUser, setCurrentUser] = useState();
        const [registrationToggle, setRegistrationToggle] = useState(false);
        const [recycleKit, setRecycleKit] = useState('')
        const [kitRoute, setKitRoute] = useState("")
        const [shipRoute, setShipRoute] = useState("")
        const [totalWeight, setTotalWeight] = useState('')
        const smallKit = 'price_1P9bLwEQljKfhE8e7bhPREDu'
        const mediumKit = 'price_1P9ZJiEQljKfhE8e3XLugoTV'
        const largeKit = 'price_1P9bGPEQljKfhE8encU8YwWm'
        const mediumGroundNoKit = 'price_1P9bIOEQljKfhE8eZoN7ketg'
        const largeGroundNoKit = 'price_1P9bJWEQljKfhE8exXWtfTuS'
        const smallGround = 'shr_1P9Z8fEQljKfhE8e2XPPNXaX'
        const mediumGround = 'shr_1P9bGuEQljKfhE8ekOWjKiek'
        const largeGround = 'shr_1P9bHUEQljKfhE8erdYAIaka'
        const [selectedKits, setSelectedKits] = useState(null)
        const [isLoggedIn, setIsLoggedIn] = useState(false)
        const [errorMessage, setErrorMessage] = useState('');
        const [minValue, setMinValue] = useState()
        const [maxValue, setMaxValue] = useState()
        const [quoteLoading, setQuoteLoading] = useState(false)
        const [isPopupVisible, setIsPopupVisible] = useState(false);
        const [isOpen, setIsOpen] = useState(true);
        const [promptError, setPromptError] = useState('')
        const [referral, setReferral] = useState('')
        const [referralValid, setReferralValid] = useState(false)
        const [referralError, setReferralError ] = useState(false)
        const [searchQuery, setSearchQuery] = useState(false)
        const [isChecked, setIsChecked] = useState(false);
        const location = useLocation();
        const handleToggle = () => setIsChecked(prevState => !prevState);
        

        
          const [result, setResult] = useState(null);
  
  // Initialize Google Analytics and set up gtag
  useEffect(() => {
    // Inject the Google Analytics script
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-CJQ1ZSGSDW';
    script.async = true;
    document.head.appendChild(script);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    function gtag(){ window.dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', 'G-CJQ1ZSGSDW');
  }, []);  // Empty dependency array to ensure it's only called once

  // Track virtual page views when pageCount changes
  useEffect(() => {
    const pagePath = `/upcycle/page-${pageCount}`;  // Customize this to represent different virtual pages
    if (window.gtag) {
      window.gtag('config', 'G-CJQ1ZSGSDW', {
        page_path: pagePath,  // Simulate different pages by sending a custom path
      });
    }
  }, [pageCount]);  // Fires every time `pageCount` changes
        

        const newSearch = () => {
            setSearchQuery(false)
        };

        const checkReferral = (e, referral) =>{
            e.preventDefault()
            if(referral === "RECYCLE20"){
                setReferralValid(true)
                
            }
            else
             setReferralValid(false)
             setReferralError(true)
        }

        const toggleCollapse = () => {
            setIsOpen(!isOpen);
        }

        const handleMouseEnter = () => {
        setIsPopupVisible(true);
        };

        const handleMouseLeave = () => {
        setIsPopupVisible(false);
        };

        useEffect(() => {
          

        }, [selectedDevices])

        
        useEffect(() =>{
             // Calculate total weight in grams
        const totalWeightGrams = selectedDevices.reduce((total, device) => {
            // Convert each device weight to grams and add to total
            return total + device.weight // Assuming device weight is in pounds
        }, 0);

        // Determine recycling kit value based on total weight
        let kitValue = '';
        if (totalWeightGrams < 5) { // Less than 5 pounds
            kitValue = '3.99';
            setKitRoute(smallKit);
            setShipRoute(smallGround);
        } else if (totalWeightGrams <= 10) { // Between 5 and 10 pounds
            kitValue = '7.99';
            setKitRoute(mediumKit);
            setShipRoute(smallGround);
        } else { // Greater than 10 pounds
            kitValue = '11.99';
            setKitRoute(largeKit);
            setShipRoute(smallGround);
        }

        // Set the recycling kit value
        setRecycleKit(kitValue);
        }
        ,[selectedDevices])
          
        useEffect(()=> {
          if (selectedDevices) {
            selectedDevices.map((device) => {
              if (!device.hasOwnProperty('interest') || device.interest === null) {
                if(device.condition === "Good")
                device.interest = "upcycle";
                else
                device.interest = "recycle"
              }
              return device;
            });
          }
        },[pageCount === 3])

        function getCookie(name) {
            const cookieValue = document.cookie.split('; ')
                .find(cookie => cookie.startsWith(name + '='))
                ?.split('=')[1];
            return cookieValue ? decodeURIComponent(cookieValue) : null;
        }
    
        function handlePrompt(e) {
            e.preventDefault();
            setLoading(true);
            setSelectedDevices([]);
            setPromptError('');
            fetch('https://terraforge-backend-4aaed00c0cf5.herokuapp.com/response_gen', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ prompt: prompt })
            })
            .then(response => response.json())
            .then(data => {
                
        try {
          const responseData = JSON.parse(data.response); // Parse the JSON string in the response field
         
          
          if (!Array.isArray(responseData.devices)) {
            throw new Error("Response is not an array");
          }

          if (responseData.devices.length <= 0){
              setPromptError("Please try again with an electronic device.")
          }

          setDevices(responseData.devices);
          setSearchQuery(true);
        } catch (error) {
          console.error("Error parsing response or response is not iterable:", error);
          setDevices([]); // Clear any previous devices
          setPromptError("Invalid response received. Please try again with an electronic device.");
        }
        setLoading(false);
            })           
            .catch(error => {
                console.error('Error:', error);
                setLoading(false); // Set loading state to false in case of error
            });
        }

        function handleLandingPrompt(promptData) {
          setLoading(true);
          setSelectedDevices([]);
          setPromptError('');
          fetch('https://terraforge-backend-4aaed00c0cf5.herokuapp.com/generate_response/', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'X-CSRFToken': getCookie('csrftoken')
              },
              body: JSON.stringify({ prompt: promptData })
          })
          .then(response => response.json())
          .then(data => {
             
      try {
        const responseData = JSON.parse(data.response); // Parse the JSON string in the response field
       
        
        if (!Array.isArray(responseData.devices)) {
          throw new Error("Response is not an array");
        }

        if (responseData.devices.length <= 0){
            setPromptError("Please try again with an electronic device.")
        }

        setDevices(responseData.devices);
        setSearchQuery(true);
      } catch (error) {
        console.error("Error parsing response or response is not iterable:", error);
        setDevices([]); // Clear any previous devices
        setPromptError("Invalid response received. Please try again with an electronic device.");
      }
      setLoading(false);
          })           
          .catch(error => {
              console.error('Error:', error);
              setLoading(false); // Set loading state to false in case of error
          });
      }

        function handleRegistration(e) {
            e.preventDefault();
            client.post(
              "/register/",
              {
                email: email,
                username: username,
                password: password
              }
            ).then(function(res) {
              client.post(
                "/login/",
                {
                  email: email,
                  password: password
                }
              ).then(function(loginRes) {
                
                const userData = loginRes.data;
                
                setCurrentUser(true);
                localStorage.setItem('authToken', loginRes.token);
              });
            });
          }

          const handleRegister = (e) => {
            e.preventDefault();
            const normalizedEmail = email.trim().toLowerCase(); // Normalize email to lowercase
            fetch('https://terraforge-backend-4aaed00c0cf5.herokuapp.com/register', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ email: normalizedEmail, password }),
            })
            .then(response => {
              if (response.ok) {
                return response.json();
              } else {
                throw new Error('Failed to register');
              }
            })
            .then(data => {
              
          
              // After successful registration, log the user in
              setShipping(normalizedEmail); // Pass email and password to the login function
          
              // Store the authentication token if you receive one
              localStorage.setItem('authToken', data.token);
            })
            .catch(error => {
              console.error('Error:', error); // Log any errors to the console
              // Handle errors here (e.g., show an error message)
              setErrorMessage('Invalid email or password. Please try again.');
            });
          };
          
          const handleSubsequentLogin = (email, password) => {
            
            fetch('https://terraforge-backend-4aaed00c0cf5.herokuapp.com/login', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ email, password }),
            })
            .then(response => {
              if (response.ok) {
                return response.json();
              } else {
                throw new Error('Failed to log in');
              }
            })
            .then(data => {
              setCurrentUser(data)
              setIsLoggedIn(true);
              setShipping(); // Log the response data to the console
              // Handle successful login, e.g., redirect to a different page or store user data
              localStorage.setItem('authToken', data.token); // Store the authentication token
            })
            .catch(error => {
              console.error('Error:', error); // Log any errors to the console
              // Handle errors here (e.g., show an error message)
              setErrorMessage('Login failed. Please try again.');
            });
          };

          const handleLogin = (e) => {
            e.preventDefault();
            fetch('https://terraforge-backend-4aaed00c0cf5.herokuapp.com/login', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ email, password }),
            })
            .then(response => {
              if (response.ok) {
                return response.json();
              } else {
                throw new Error('Failed to log in');
              }
            })
            .then(data => {
              setCurrentUser(data)
              setIsLoggedIn(true); // Log the response data to the console
              // Handle successful login, e.g., redirect to a different page or store user data
              localStorage.setItem('authToken', data.token); // Store the authentication token
            })
            .catch(error => {
              console.error('Error:', error); // Log any errors to the console
              // Handle errors here (e.g., show an error message)
              setErrorMessage('Login failed. Please try again.');
            });
          };
        
        
        function setShipping(normalizedEmail) {
            
            setLoading(true);
            
            fetch('https://terraforge-backend-4aaed00c0cf5.herokuapp.com/create_shipping', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    fullName: shippingDetails.fullName,
                    address: shippingDetails.address,
                    city: shippingDetails.city,
                    postalCode: shippingDetails.postalCode,
                    userEmail: normalizedEmail
                })
            })
            .then(function(response) {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to create shipping instance');
                }
            })
            .then(function(data) {
                
                createItems(data.shipping_id);
            })
            .catch(function(error) {
                console.error("Error creating shipping instance:", error.message);
            });
        }
        
        function createItems(shipping_id) {
            // Iterate over selectedDevices and create items for each device
            let totalDeviceWeight = selectedDevices.reduce((total, device) => total + device.weight, 0);

            selectedDevices.forEach(function(device) {
                fetch('https://terraforge-backend-4aaed00c0cf5.herokuapp.com/create_item', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        shipping_id: shipping_id,
                        brand: device.brand,
                        condition: device.condition,
                        interest: device.interest + String(selectedOption),
                        model: device.model,
                        type: device.type,
                        weight: device.weight,
                        year: device.year 
                    })
                })
                .then(function(response) {
                    if (!response.ok) {
                        throw new Error('Failed to create item instance');
                    }
                })
                .catch(function(error) {
                    console.error("Error creating item instance:", error.message);
                });
            })
            createCheckoutSession(totalDeviceWeight);
        }

        function setLabel(totalDeviceWeight) {
            fetch('https://terraforge-backend-4aaed00c0cf5.herokuapp.com/create_label_referral', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    fullName: shippingDetails.fullName,
                    address: shippingDetails.address,
                    city: shippingDetails.city,
                    state: shippingDetails.state, 
                    postalCode: shippingDetails.postalCode,
                    userEmail: email, 
                    totalWeight: totalDeviceWeight
                })
            })
            .then(function(response) {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to create shipping instance');
                }
            })
            .then(function(data) {
                window.location.href = '/ordered';
                setLoading(false) // Redirect to /ordered page
            })
            .catch(function(error) {
                console.error("Error creating shipping instance:", error.message);
            });
        }
        
        
        
        

        const toggleDeviceSelection = (device) => {
           
            setSelectedDevices((prevSelectedDevices) => {
                if (prevSelectedDevices.includes(device)) {
                    return prevSelectedDevices.filter((selectedDevice) => selectedDevice !== device);
                } else {
                    return [...prevSelectedDevices, device];
                }
            });
           
        };

        const CommonDeviceSelection = (e,device) => {
          
          setSelectedDevices([{
            brand: device.brand, 
            model: device.model, 
            type: device.type, 
            weight: device.weight, 
            year: device.year
          }])
          setPageFwd(e);
      };

      function setPageFwd(e) {
        e.preventDefault();
        setPageCount(prevPageCount => Math.min(5, prevPageCount + 1)); // Ensure pageCount doesn't go above 5
        
        // Scroll the page to the top-left corner
        window.scrollTo(0, 0);
    }
        function setConditionFwd() {
          setPageCount(3); // Ensure pageCount doesn't go above 4

          window.scrollTo(0, 0);
      }
      

        function setPageQuote(e) {
            e.preventDefault();
            handleThought(e);
            setPageCount(prevPageCount => Math.min(5, prevPageCount + 1)); // Ensure pageCount doesn't go above 4
        }

        function setPage(e){
            e.preventDefault()
            setPageTwo(true)
        }

        function conditionSelection(){
            setConditionSelected(true)
        }

        const handleInterestSelection = (device, interest) => {
           
            // Update the selectedDevices array to include the selected interest for the corresponding device
            setSelectedDevices(prevSelectedDevices => {
                const updatedDevices = prevSelectedDevices.map(prevDevice => {
                    if (prevDevice.model === device.model) {
                        // If the device matches, update its interest property
                        return { ...prevDevice, interest };
                    }
                    return prevDevice;
                });
                return updatedDevices;
            });
            
        };
        
        const handleConditionSelection = (e,device, condition) => {
            
            // Update the selectedDevices array to include the selected interest for the corresponding device
            setSelectedDevices(prevSelectedDevices => {
                const updatedDevices = prevSelectedDevices.map(prevDevice => {
                    if (prevDevice.model === device.model) {
                        // If the device matches, update its interest property
                        return { ...prevDevice, condition };
                    }
                    return prevDevice;
                });
                setConditionFwd();
                return updatedDevices;
                
            });
            
        };

        const handleInputChange = (e) => {
            const { name, value } = e.target;
            setShippingDetails(prevState => ({
                ...prevState,
                [name]: value
            }));
        };

        const convertToGrams = (weight) => {
            if (weight.includes('kg')) {
                // Convert kilograms to grams
                return parseFloat(weight) * 1000;
            } else if (weight.includes('grams')) {
                // Extract the numeric value and convert to number
                return parseFloat(weight);
            } else {
                // Handle other cases or invalid weights
                return 0;
            }
        };
        
        const totalWeightGrams = selectedDevices.reduce((total, device) => {
            // Convert each device weight to grams and add to total
            return total + device.weight
        }, 0);

        
        const handleOptionChange = (option) => {
          if (option === "recyclingKit") {
            setSelectedOption(option);
        
            const updatedDevices = selectedDevices.map((device) => ({
              ...device,
              interest: 'upcycle'
            }));
        
            setSelectedDevices(updatedDevices);
          } else if (option === "ownBox") {
            setSelectedOption(option);
        
            const updatedDevices = selectedDevices.map((device) => ({
              ...device,
              interest: 'ownBox'
            }));
        
            setSelectedDevices(updatedDevices);
          }
        };

        const createCheckoutSession = async (totalDeviceWeight) => {
            handleBoxRequest(selectedOption)
            try {
                const response = await fetch('https://terraforge-backend-4aaed00c0cf5.herokuapp.com/create-checkout-session', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        kitRoute: 'price_1Q2NNpEQljKfhE8ehhEUbPYb',
                        shipRoute: shipRoute,
                        shippingDetails: {
                            fullName: shippingDetails.fullName,
                            address: shippingDetails.address,
                            city: shippingDetails.city,
                            state: shippingDetails.state,
                            country: shippingDetails.country,
                            postalCode: shippingDetails.postalCode,
                        },
                        currentUser: {
                            email: email
                        },
                        totalDeviceWeight: totalDeviceWeight,
                    }),
                });
        
                if (!response.ok) {
                    throw new Error('Failed to create checkout session');
                }
        
                const data = await response.json();
                
        
                // Navigate to the redirect URL
                window.location.href = data.url;
                //setLabel(totalDeviceWeight);
            } catch (error) {
                console.error('Error:', error.message);
            }
        };
        
        
        function handleKitSelection(e){
            e.preventDefault()
            if (selectedKits === 'small')
                setSelectedDevices([{
                    brand: 'Standard',
                    condition: 'Send up to 4 pounds of e-waste in any condition.',
                    interest: 'Recycle',
                    model: 'Small Devices',
                    type: 'Small handheld electronics like smartphones and portable devices.',
                    weight: 4,
                    year: '2024', 
                    image: Micro   
                }])
            else if(selectedKits === 'medium')
            setSelectedDevices([{
                brand: 'Standard',
                condition: 'Send up to 9 pounds of e-waste in any condition.',
                interest: 'Recycle',
                model: 'Medium Devices',
                type: 'Household devices like laptops and larger tablets.',
                weight: 9,
                year: '2024', 
                image: Midi   
            }])
            else 
            setSelectedDevices([{
                brand: 'Standard',
                condition: 'Send up to 19 pounds of e-waste in any condition.',
                interest: 'Recycle',
                model: "Large Devices",
                type: 'Multiple devices or larger electronic devices.',
                weight: 19,
                year: '2024',
                image: Macro   
            }])
            setPageCount(3)
            window.scrollTo(0, 0);
        }

        function handleThought(e) {
            // Set loading state to true when prompt is submitted
            e.preventDefault()
            setQuoteLoading(true);
            
           fetch('http://127.0.0.1:5000/response_gen', {
               method: 'POST',
               headers: {
                   'Content-Type': 'application/json'
               },
               body: JSON.stringify({ prompt: selectedDevices[0].model, howBad: selectedDevices[0].condition })
           })
           .then(response => response.json())
           .then(data => {
            let content = data.response;

            // Assuming content is a string like the following:
            // '{\n  "estimated_value_range": {\n    "min_value": 50,\n    "max_value": 150\n  },\n  "currency": "USD",\n  "condition": "used",\n  "model": "iPhone 7"\n}'
            content = content.replace(/```json|```/g, '');
            // Parse the JSON content from the response
            const parsedContent = JSON.parse(content);
      
            // Extract min_value and max_value
            const min_value = parsedContent.min_value;
            const max_value = parsedContent.max_value;
            
            const adjustedMaxValue = Math.floor(max_value * 0.7); // or Math.ceil(max_value * 0.7) or Math.round(max_value * 0.7)
            const adjustedMinValue = Math.floor(min_value * 0.7); // or Math.ceil(min_value * 0.7) or Math.round(min_value * 0.7)

            setMaxValue(adjustedMaxValue);
            setMinValue(adjustedMinValue);
            setQuoteLoading(false)
           })           
           .catch(error => {
               console.error('Error:', error);
           
           });
       }
        
        return (
            <Body>
            {pageCount === 1 && (
            <Container>
            <HeadingContainer>
              <Title>Recycle your Electronics within minutes.</Title>
              <Separator />
              
               <>
                  <InputContainer>
                    <StyledInput
                      type="text"
                      value={prompt}
                      onChange={(e) => setPrompt(e.target.value)}
                      placeholder="You can say something like 'a laptop from 2017'"
                    />
                    <InputButton onClick={(e) => handlePrompt(e)}>Submit</InputButton>
                  </InputContainer>
                  <PromptText>
                    We'll use AI to find what you would like to recycle - <i>providing the item's brand, model, or year is helpful to refine results.</i>
                  </PromptText>


                  {/*set on off toggle to select multiple devices, if not device card is a click to activate*/}
                </>
              
            </HeadingContainer>
            
            {loading ? (
              <LoadingContainer>
                <Spinner />
                <LoadingText>Sit tight. We're working on finding the most likely item. This could take up to 30 seconds.</LoadingText>
              </LoadingContainer>
            ) : (
              <>
                {promptError && !isOpen && (
                  <div>
                    <img src={notFound} alt="Not Found" />
                    <H2>{promptError}</H2>
                  </div>
                )}
                {devices.length > 0 && (
                  <>
                  <HeadingContainer>
                    <TitleH2>Here's what we found:</TitleH2>
                    <Separator />
                  </HeadingContainer>
                </>
                )}
                
                <DeviceContainer>
                  {devices.map(device => (
                    <DeviceCard key={device.model}
                      $isselected={selectedDevices.includes(device)}
                      onClick={() => toggleDeviceSelection(device)}
                    >
                      <BoldDetail>{device.model}</BoldDetail>
                      <DeviceDetail><i>{device.brand}</i></DeviceDetail>
                      <DeviceDetail>{device.year}</DeviceDetail>
                    </DeviceCard>
                  ))}
                </DeviceContainer>
                {selectedDevices.length > 0 && (
                  <DeviceButton onClick={(e) => setPageFwd(e)}>Continue</DeviceButton>
                )} 
                </>
            )}
            <TitleH2>Select from some common tech you'd like to recycle:</TitleH2>
            <DeviceList handleDeviceSelect={CommonDeviceSelection}/>
            <TitleH2>Have a few devices you'd like to recycle - select from one of our recycling kits.</TitleH2>
            <ProcessContainer>
          <DeviceCardWrapper>
            <DeviceCard
              $isselected={selectedKits === 'small'}
              onClick={() => setSelectedKits('small')}
            >
              <img src={Micro} alt="Small Devices" />
              <RecycleDetail>Small Devices</RecycleDetail>
              <RecycleInfo>Send up to 4 lbs - 8" x 6" x 4" Small Box (optional at checkout) </RecycleInfo>
              <i>Perfect for small handheld electronics like smartphones and portable devices.</i>
            </DeviceCard>
            {selectedKits === 'small' && <DeviceButton onClick={handleKitSelection}>Recycle</DeviceButton>}
          </DeviceCardWrapper>
          <DeviceCardWrapper>
            <DeviceCard
              $isselected={selectedKits === 'medium'}
              onClick={() => setSelectedKits('medium')}
            >
              <img src={Midi} alt="Medium Devices" />
              <RecycleDetail>Medium Devices</RecycleDetail>
              <RecycleInfo>Send up to 10 lbs - 15" x 12" x 6" Medium Box (optional at checkout)</RecycleInfo>
              <i>Suitable for household devices like laptops and larger tablets.</i>
            </DeviceCard>
            {selectedKits === 'medium' && <DeviceButton onClick={handleKitSelection}>Recycle</DeviceButton>}
          </DeviceCardWrapper>
          <DeviceCardWrapper>
            <DeviceCard
              $isselected={selectedKits === 'large'}
              onClick={() => setSelectedKits('large')}
            >
              <img src={Macro} alt="Large Devices" />
              <RecycleDetail>Large Devices</RecycleDetail>
              <RecycleInfo>Send up to 20 lbs - 24" x 18" x 18" Large Box (optional at checkout)</RecycleInfo>
              <i>Designed to hold multiple devices or larger electronic devices.</i>
            </DeviceCard>
            {selectedKits === 'large' && <DeviceButton onClick={handleKitSelection}>Recycle</DeviceButton>}
          </DeviceCardWrapper>
        </ProcessContainer>
          </Container>
            )}
                {pageCount == 2 && (
                    <Container>
                        {selectedDevices.map((device, index) => (
                    <div key={index}>
                 
                    <HeadingContainer>
                        <Question>Does your {device.model} power on?</Question>
                        <p>This helps us understand whether we can remove your data via software or if manual data destruction is required.</p>
                    </HeadingContainer>
                  
                    <OptionContainer>
                    <OptionCardsContainer>
                    <OptionCard $isselected={device.condition === 'Good'} onClick={(e) => handleConditionSelection(e,device, 'Good')}>
                        <OptionName>Yes</OptionName>
                        <OptionDetail>The Device powers on when plugged in.</OptionDetail>
                        <OptionDetail>The device will hold a charge.</OptionDetail>
                        <OptionDetail>No noticeable scratches, dents, or defects</OptionDetail>
                    </OptionCard>
                     <OptionCard $isselected={device.condition === 'Seen Better Days'} onClick={(e) => handleConditionSelection(e,device,'Seen Better Days')}>
                        <OptionName>No</OptionName>
                        <OptionDetail>The device will not turn on.</OptionDetail>
                        <OptionDetail>Multiple scratches, dents, or cracks</OptionDetail>
                         <OptionDetail>Crashes, freezes, or performance issues</OptionDetail>
                        <OptionDetail>May require repairs or replacement parts</OptionDetail>   
                    </OptionCard>
                    </OptionCardsContainer>
                </OptionContainer>
                        </div>
                        ))}
                        <ButtonContainer>
                            {selectedDevices.includes(selectedDevices.condition) && <Button onClick={(e) => setPageFwd(e)}>Continue</Button>}
                        </ButtonContainer>
                    </Container>
                )}
                {/*
                {pageCount === 3 && (
                   <Container>
                       <QuestionContainer>
                        <Question>Let's explore the potential for upcycling or recycling your items. </Question>
                    </QuestionContainer>
                    <Separator />
                   {selectedDevices.map((device, index) => (
                        <div key={index}>
                            
                             <InterestContainer>
                             <H1>Your {device.model}</H1>
                    {(device.condition === 'Poor' || device.condition === 'Seen Better Days') && (
                        <>
                            <InterestCard $isselected={device.interest === 'Recycle'} onClick={() => handleInterestSelection(device, 'Recycle')}>
                                <InterestName>Recycle.</InterestName>
                                <InterestDetail>Choose this option if you want to recycle the item.</InterestDetail>
                            </InterestCard>
                        </>
                    )}
                    {(device.condition === 'Fair' || device.condition === 'Good') && (
                        <>
                            <InterestCard $isselected={device.interest === 'Recycle'} onClick={() => handleInterestSelection(device, 'Recycle')}>
                                <InterestName>Recycle.</InterestName>
                                <InterestDetail>Choose this option if you want to recycle your {device.model}.</InterestDetail>
                            </InterestCard>
                            {(device.year > 2013) &&(
                            <InterestCard $isselected={device.interest === 'Upcycle'} onClick={() => handleInterestSelection(device, 'Upcycle')}>
                                <InterestName>Upcycle - Earn Cash.</InterestName>
                                <InterestDetail> Based on your {device.model}'s year and condition, it might be valuable. Choose this option, and we'll provide you an estimate for your item. <br/> <br/> <i>Please note - it may take 3-5 business days after we receive your item to provide an estimate.</i></InterestDetail>
                            </InterestCard>
                            )}
                        </>
                    )}

                     
                </InterestContainer>
              </div>
               ))}
                        <ButtonContainer>
                                       <Button onClick={(e) => setPageQuote(e)}>Continue</Button>
                        </ButtonContainer>
               </Container>
               
                )}
                */}
                {pageCount === 3 && (
                     <SummaryContainer>
                    
                   
        <DeviceAndListContainer>
 
        <DeviceSummary>
            { /* 
      <GreenFont>Our first 1,000 members will earn 30 credits for any items recycled.</GreenFont>
      <p><i>Credits will unlock after the first 10,000 devices are recycled. Credits earn access to a percentage of our net revenue.</i></p>
                */}
                <h2>Item Summary</h2>
      {selectedDevices.map((device, index) => (
        <dl key={index}>
          <div>
            <dt>Model:</dt>
            <dd>{device.model}</dd>
          </div>
          <div>
            <dt>Condition:</dt>
            <dd>{device.condition}</dd>
          </div>
          <div>
            <dt>Type:</dt>
            <dd>{device.type}</dd>
          </div>
        </dl>
      ))}
      <SummarySeparator />
      <H1>How would you like to send your items?</H1>
      <CheckboxContainer>
  <Checkbox
    type="checkbox"
    id="recyclingKit"
    checked={selectedOption === 'recyclingKit'}
    onChange={() => handleOptionChange('recyclingKit')}
  />
  <DeviceDetail htmlFor="recyclingKit">
    Add a recycling Kit - box and packaging materials to ship the device.
  </DeviceDetail>
</CheckboxContainer>

<CheckboxContainer>
  <Checkbox
    type="checkbox"
    id="ownBox"
    checked={selectedOption === 'ownBox'}
    onChange={() => handleOptionChange('ownBox')}
  />
  <DeviceDetail htmlFor="ownBox">
    I have my own box - just send me the shipping label.
  </DeviceDetail>
</CheckboxContainer>
              <StyledList>
            
                     <H1>What's Next?</H1>
                     <StyledListItem>
    <IconWrapper>
      <WhatsNextIcon src={shield} alt="shield" />
    </IconWrapper>
    <Content>
    <WhatsNextShield>100% Data Protection</WhatsNextShield>
      <Description><i> We ensure all of your data is safely wiped from your device before processing it for recycling.</i></Description>
    </Content>
  </StyledListItem>
  <StyledListItem>
    <IconWrapper>
      <WhatsNextIcon src={box} alt="Shipment" />
    </IconWrapper>
    <Content>
      <WhatsNextTitle>Send it</WhatsNextTitle>
      <Description>Drop off your items at your nearest shipping provider or schedule a pickup.</Description>
    </Content>
  </StyledListItem>
  <StyledListItem>
    <IconWrapper>
      <WhatsNextIcon src={RecycleIcon} alt="Recycling" />
    </IconWrapper>
    <Content>
      <WhatsNextTitle>Recycle</WhatsNextTitle>
      <Description>Once we receive your electronics, we'll safely recycle them according to environmental regulations.</Description>
    </Content>
  </StyledListItem>

</StyledList>  
    
    </DeviceSummary>
  
  
</DeviceAndListContainer>     

                   
                     <ShippingForm onSubmit={handleRegister}>
          
      <h1>Create your shipping label</h1>
      <h2>Early-access member:</h2>
      <p>By recycling with Basile today, you gain early access to any future revenue sharing, discounts, or upcycling opportunites.</p>
      <div>
        <label htmlFor="email">Email</label>
        <input
          type="text"
          id="email"
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <div>
        <label htmlFor="password">Password</label>
        <input
          type="password"
          id="password"
          placeholder="create your password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </div>
      
      <h2>Shipping Details</h2>
      
      <div>
        <label htmlFor="fullName">Full Name</label>
        <input
          type="text"
          id="fullName"
          name="fullName"
          placeholder="Enter your full name"
          value={shippingDetails.fullName}
          onChange={handleInputChange}
          required
        />
      </div>
      
      <div>
        <label htmlFor="address">Address</label>
        <input
          type="text"
          id="address"
          name="address"
          placeholder="Enter your address"
          value={shippingDetails.address}
          onChange={handleInputChange}
          required
        />
      </div>
      
      <div>
        <label htmlFor="city">City</label>
        <input
          type="text"
          id="city"
          name="city"
          placeholder="Enter your city"
          value={shippingDetails.city}
          onChange={handleInputChange}
          required
        />
      </div>
      
      <div>
        <label htmlFor="state">State</label>
        <input
          type="text"
          id="state"
          name="state"
          placeholder="Enter your state"
          value={shippingDetails.state}
          onChange={handleInputChange}
          required
        />
      </div>
      
      <div>
        <label htmlFor="postalCode">Postal Code</label>
        <input
          type="text"
          id="postalCode"
          name="postalCode"
          placeholder="Enter your postal code"
          value={shippingDetails.postalCode}
          onChange={handleInputChange}
          required
        />
      </div>
      
      <button type="submit" disabled={loading}>
        {loading ? 'Loading...' : 'Submit'}
      </button>
      {loading && <p><i>Do not refresh the page. This can take up to 10 seconds.</i></p>}
      {errorMessage && <p style={{color: 'red'}}>{errorMessage}</p>}
    </ShippingForm>

                 </SummaryContainer>
                )}
            {pageCount === 5 && (
            <div>
                    <SummaryHeading>Shipping Details</SummaryHeading>
                     <ShippingForm onSubmit={(e) => setShipping(e)} >
                         <label>
                             Full Name:
                             <input type="text" name="fullName" value={shippingDetails.fullName} onChange={handleInputChange} required />
                         </label>
                         <label>
                             Address:
                             <input type="text" name="address" value={shippingDetails.address} onChange={handleInputChange} required />
                         </label>
                         <label>
                             City:
                             <input type="text" name="city" value={shippingDetails.city} onChange={handleInputChange} required />
                         </label>
                         <label>
                            State:
                            <input type="text" name="state" value={shippingDetails.state} onChange={handleInputChange} required />
                        </label>
                         <label>
                             Country:
                             <input type="text" name="country" value={shippingDetails.country} onChange={handleInputChange} required />
                         </label>
                         <label>
                             Postal Code:
                             <input type="text" name="postalCode" value={shippingDetails.postalCode} onChange={handleInputChange} required />
                         </label>
                         <button type="submit" disabled={loading}>
                         {loading ? 'Loading...' : 'Submit'}
                        </button>
                     </ShippingForm>
            </div>
            )}
         </Body>
        );
    }

export default UpcycleForm;