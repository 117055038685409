import React from 'react';
import styled from 'styled-components';
import intro from "../assets/introVideo.mp4"
import thumbnail from "../assets/IntroThumbnail.png"

const VideoContainer = styled.div`
  width: 70%;
  height: 70%;
  max-width: 1200px; /* Adjust this to fit your layout */
  margin: 30px auto; /* Center the video and add some margin */
  padding: 0 15px;
  

  @media (max-width: 768px) {
    max-width: 80%; /* Ensure it spans the width of the container on mobile */
  }
`;

const Video = styled.video`
  width: 100%;
  height: auto;
  border-radius: 10px; /* Optional: Adds a slight border-radius to the video */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Optional: Adds a shadow for a more polished look */
`;

function IntroVideo() {
  return (
    <VideoContainer>
      <Video controls poster={thumbnail} playsInline>
        <source src={intro} type="video/mp4" />
        Your browser does not support the video tag.
      </Video>
    </VideoContainer>
  );
}

export default IntroVideo;
