import React, { useState } from 'react';
import styled from 'styled-components';


const Body = styled.div`
  margin-top: 90px;
  background-color: #f8f6ea;
  display: flex; /* Make children align side by side */
  justify-content: space-between; /* Distribute space between them */
  align-items: flex-start; /* Align them at the top */
  width: 100%; /* Ensure it spans the full width of the page */
  padding: 0 40px; /* Optional: add some padding */

`
const TextContainer = styled.div`
   margin-top: 80px;
  margin-right: 40px;
  width: 50%; /* Adjust width as needed */
`
const TextTitle = styled.h1`
    color: #385b4f;
    font-size: 69px;
    padding: 0px;
    margin-bottom: 1px;
`
const TextH2 = styled.h2`
    color: #d88a59;
    font-size: 34px;
    width: 80%;
`
const FormContainer = styled.div`
  width: 50%; 
  margin-top: 60px;
  margin-bottom: 30px;
  
`;

const FormH1 = styled.div`
 text-align: left;
 color: #f8f6ea;
 font-size: 28px;
 font-weight: bold;
 margin-bottom: 20px;
`

const Form = styled.form`
  background: #385b4f;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
`;

const DeviceContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: row;
  margin-bottom: 10px;
`;

const DeviceCard = styled.div`
  text-align: center;
  background-color: #f8f6ea;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 10px;
  height: 40px;
  width: 90px;
  color: #385b4f;
  transition: transform 0.3s ease;
  cursor: pointer;
  border: 2px solid transparent;

  ${({ $isselected }) =>
    $isselected &&
    `
    border: 2px solid #d88a59;
  `}
`;

const DeviceDetail = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 11px;
  color: #333;
  margin-bottom: 8px;
  font-weight: bold;
`;

const Label = styled.label`
  color: #f8f6ea;
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f8f6ea;
`;

const Input = styled.input`
  width: 95%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f8f6ea;
  
`;

const Button = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #d88a59;
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: #C0784D;
  }
`;

function Waitlist() {
  const [formData, setFormData] = useState({
    buyerSeller: '',
    interestedItems: [],
    minimumSpend: '',
    email: '',
    businessName: '',
    country: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const toggleItemSelection = (item) => {
    setFormData((prevFormData) => {
      const selectedItems = prevFormData.interestedItems.includes(item)
        ? prevFormData.interestedItems.filter((i) => i !== item)
        : [...prevFormData.interestedItems, item];
      return { ...prevFormData, interestedItems: selectedItems };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Data Submitted: ', formData);
    // Add form submission logic here (e.g., sending data to a server)
  };

  const { interestedItems } = formData;

  return (
      <Body>
          <TextContainer>
              <TextTitle>We're Working on <br /> something BIG.</TextTitle>
              <TextH2>Gain early access to our Wholesale marketplace to buy direct from the best Recyclers in the US.</TextH2>
          </TextContainer>
    <FormContainer>
        
      <Form onSubmit={handleSubmit}>
      <FormH1>Join the Waitlist.</FormH1>
        <Label htmlFor="email">Email</Label>
        <Input type="email" id="email" name="email" onChange={handleChange} />

        <Label htmlFor="businessName">Business Name</Label>
        <Input type="text" id="businessName" name="businessName" onChange={handleChange} />

        <Label htmlFor="country">Country</Label>
        <Input type="text" id="country" name="country" onChange={handleChange} />
        <Label htmlFor="buyerSeller">Are you a buyer or seller?</Label>
        <Select name="buyerSeller" id="buyerSeller" onChange={handleChange}>
          <option value="">Select an option</option>
          <option value="buyer">Buyer</option>
          <option value="seller">Seller</option>
        </Select>
        <Label htmlFor="minimumSpend">What is your minimum spend per month?</Label>
        <Select name="minimumSpend" id="minimumSpend" onChange={handleChange}>
          <option value="">Select an option</option>
          <option value="<500">Less than $500</option>
          <option value="500-1000">$500 - $1,000</option>
          <option value="1000-10000">$1,000 - $10,000</option>
          <option value="10000-25000">$10,000 - $25,000</option>
          <option value="25000+">$25,000 and up</option>
        </Select>


        <Label htmlFor="interestedItems">Items you're interested in purchasing</Label>
        <DeviceContainer>
          <DeviceCard
            $isselected={interestedItems.includes('laptops')}
            onClick={() => toggleItemSelection('laptops')}
          >
            <DeviceDetail>Laptops</DeviceDetail>
          </DeviceCard>
          <DeviceCard
            $isselected={interestedItems.includes('smartphones')}
            onClick={() => toggleItemSelection('smartphones')}
          >
            <DeviceDetail>Smartphones</DeviceDetail>
          </DeviceCard>
          <DeviceCard
            $isselected={interestedItems.includes('misc-electronics')}
            onClick={() => toggleItemSelection('misc-electronics')}
          >
            <DeviceDetail>Misc Electronics</DeviceDetail>
          </DeviceCard>
          <DeviceCard
            $isselected={interestedItems.includes('raw-materials')}
            onClick={() => toggleItemSelection('raw-materials')}
          >
            <DeviceDetail>Raw Materials</DeviceDetail>
          </DeviceCard>
        </DeviceContainer>
        <Button type="submit">Join Waitlist</Button>
      </Form>
    </FormContainer>
    </Body>
  );
}

export default Waitlist;

