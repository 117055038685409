import React, { useState } from 'react';
import styled from 'styled-components';
import Iphone from "../assets/Iphone.png"
import Macbook from "../assets/Macbook.png"
import Android from "../assets/Android.png"
import Ipad from "../assets/Ipad.png"
import Pclaptop from '../assets/Pclaptop.png'

const HeadingContainer = styled.div`
  margin-bottom: 1rem;
`;

const TitleH2 = styled.h2`
  font-size: 2rem;
  color: #333;
`;

const DeviceContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  padding: 1rem;
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Prevent vertical scroll */
  max-width: 100%; /* Constrain the container to the width of its parent */
  box-sizing: border-box; /* Include padding in width calculations */
  white-space: nowrap; /* Ensure the items don't wrap */
  scroll-snap-type: x mandatory; /* Optional: Adds snap scrolling behavior */
  scroll-snap-align: start; /* Ensure each card snaps into view */

  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for a cleaner look */
  }
`;






const DeviceCard = styled.div`
  width: 180px; /* Slightly reduce width to better fit in carousel */
  height: 260px; /* Adjusted height to fit content better */
  padding: 1rem;
  border: 2px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  background-color: ${({ $isselected }) => ($isselected ? '#e0f7fa' : '#f5f5f5')};
  transition: background-color 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex-shrink: 0; /* Prevent the card from shrinking in the carousel */

  &:hover {
    background-color: #f1f1f1;
  }

  @media (max-width: 768px) {
    width: 160px; /* Further adjust width for mobile view */
    height: 240px;
  }
`;


const ImageContainer = styled.div`
  width: 100%;
  height: 120px; /* Reduce the image height slightly for better fit */
  margin-bottom: 0.5rem;
  overflow: hidden;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%; /* Ensure the image fits within the container */
    max-height: 100%; /* Preserve aspect ratio */
    object-fit: contain; /* Maintain aspect ratio while containing image within the container */
  }
`;



const BoldDetail = styled.p`
  font-weight: bold;
  font-size: 1.2rem;
`;

const DeviceDetail = styled.p`
  font-size: 1rem;
  color: #555;
`;

const DeviceList = ({handleDeviceSelect}) => {
  const [selectedDevices, setSelectedDevices] = useState([]);

  const toggleDeviceSelection = (device) => {
    setSelectedDevices((prevSelected) =>
      prevSelected.includes(device)
        ? prevSelected.filter((d) => d !== device)
        : [...prevSelected, device]
    );
  };

  const devices = [
    { model: 'iPhone', brand: 'Apple', year: '2024', image: Iphone, type: 'smartphone', weight: '2' },
    { model: 'MacBook', brand: 'Apple', year: '2024', image: Macbook, type: 'laptop' , weight: '4'},
    { model: 'Laptop (Other)', brand: 'Various', year: '2024', image: Pclaptop, type: 'Laptop', weight: '4' },
    { model: 'Phone (Other)', brand: 'Various', year: '2024', image: Android, type: 'smartphone', weight: '2'},
    { model: 'Tablet', brand: 'Various', year: '2024', image: Ipad, type: 'tablet', weight: '4' },
  ];

  return (
    <>
      <DeviceContainer>
        {devices.map((device) => (
          <DeviceCard
            key={device.model}
            $isselected={selectedDevices.includes(device)}
            onClick={(e) => handleDeviceSelect(e, device)}
          >
            <BoldDetail>{device.model}</BoldDetail>
            <ImageContainer>
              <img src={device.image} alt={device.model} />
            </ImageContainer>
          </DeviceCard>
        ))}
      </DeviceContainer>
    </>
  );
};

export default DeviceList;
