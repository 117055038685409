import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

const CounterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  padding: 22px;
  width: 90%;
  max-width: 1400px;
  margin: 44px auto;

  @media (max-width: 768px) {
    padding: 10px;
    margin: 20px auto;
  }
`;

const CounterTitle = styled.div`
  font-size: 2.85em;
  font-weight: normal;
  color: #385b4f;
  margin-bottom: 5.5px;

  @media (max-width: 768px) {
    font-size: 1.1em;
    margin-bottom: 10px;
  }
`;

const CounterValue = styled.div`
  font-size: 3.6em;
  font-weight: bold;
  color: #385b4f;
  margin-bottom: 22px;

  @media (max-width: 768px) {
    font-size: 1.6em;
    margin-bottom: 15px;
    
  }
`;

const Description = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1150px;
  margin: 2rem auto;
  background-color: #385b4f;

  p {
    font-size: 1.21em;
    line-height: 1.76;
    color: #f8f6ea;
    font-weight: 500;
    padding: 16.5px;
    width: 100%;
    margin: 0 auto;

    @media (max-width: 768px) {
      font-size: 1em;
      line-height: 1.5;
      padding: 10px;
    }
  }
`;

const Counter = () => {
  const [count, setCount] = useState(7599989);
  const counterRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
          const interval = setInterval(() => {
            setCount((prevCount) => {
              const nextCount = prevCount + 1;
              return nextCount > 7600000 ? 7600000 : nextCount;
            });
          }, 75);
          return () => clearInterval(interval);
        }
      },
      { threshold: 0.5 }
    );

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <CounterContainer ref={counterRef}>
      <CounterTitle>E-waste Generated Annually</CounterTitle>
      <CounterValue>{count.toLocaleString()} million tons.</CounterValue>
      <Description>
        <p>
          Based on current data, we produce approximately 50 million tons of e-waste annually, equivalent to almost 1,100 laptops every second. This cycle continues to grow rapidly as technology scales, leaving millions of tons of waste generated, with limited optimization on consumer-friendly platforms to send in unwanted e-waste.
        </p>
      </Description>
    </CounterContainer>
  );
};

export default Counter;


